<template>
  <page-content>

    <common-table
      ref="table"
      path="user"
      :columns="columns">

      <template slot="search">
        <a-button type="primary" ghost @click="add" v-hasPermission="['user:add']">新增</a-button>
<!--        <a-button @click="batchDelete" v-hasPermission="['user:delete']">删除</a-button>-->
<!--        <a-button v-hasPermission="['user:reset']" key="password-reset" @click="resetPassword">密码重置</a-button>-->
        <a-button v-hasPermission="['user:export']" key="export-data" @click="exportExcel">导出Excel</a-button>
      </template>

      <template slot="role" slot-scope="{record}">
        <a-tag color="green">{{record.roleName}}</a-tag>
      </template>

      <template slot="status" slot-scope="{record}">
        <a-tag color="red" v-if="record.status==0">锁定</a-tag>
        <a-tag color="cyan" v-else>有效</a-tag>
      </template>

      <template slot="operation" slot-scope="{record}">

        <div v-if="record.admin"></div>
        <template v-else>
          <action-edit v-hasPermission="['user:update']" @click="edit(record)"></action-edit>
          <action-view v-hasPermission="['user:view']" @click="view(record)"></action-view>
          <action-delete v-hasPermission="['user:delete']" @click="remove(record)"></action-delete>
          <a-badge v-hasNoPermission="['user:update','user:view','user:delete']" status="warning" text="无权限"></a-badge>
        </template>

      </template>

    </common-table>

    <!-- 用户信息查看 -->
    <user-info
      :userInfoData="userInfo.data"
      :userInfoVisiable="userInfo.visiable"
      @close="handleUserInfoClose">
    </user-info>
    <!-- 增加，修改用户 -->
    <user-edit ref="userEdit" @success="search" ></user-edit>
  </page-content>
</template>

<script>
  import UserInfo from './UserInfo'
  import UserEdit from './UserEdit'

  export default {
    components: {
      UserInfo, UserEdit
    },
    data () {
      return {
        userInfo: {
          visiable: false,
          data: {}
        },

      }
    },
    computed: {
      columns () {
        return [
          {
            title: '用户名',
            dataIndex: 'username',
          },
          {
            title: '角色',
            dataIndex: 'roleName',
            scopedSlots: {customRender: 'role'}
          },
          // {
          // title: '性别',
          // dataIndex: 'ssex',
          // customRender: (text, row, index) => {
          //   switch (text) {
          //     case '0':
          //       return '男'
          //     case '1':
          //       return '女'
          //     case '2':
          //       return '保密'
          //     default:
          //       return text
          //   }
          // },
          // filters: [
          //   { text: '男', value: '0' },
          //   { text: '女', value: '1' },
          //   { text: '保密', value: '2' }
          // ],
          // filterMultiple: false,
          // },
          {
            title: '邮箱',
            dataIndex: 'email',
          }, {
            title: '电话',
            dataIndex: 'mobile'
          }, {
            title: '状态',
            dataIndex: 'status',
            scopedSlots: {customRender: 'status'},
            filters: [
              {text: '有效', value: '1'},
              {text: '锁定', value: '0'}
            ],
            filterMultiple: false,
          }, {
            title: '创建时间',
            dataIndex: 'createTime',
            sorter: true,
          }, {
            title: '操作',
            dataIndex: 'operation',
            scopedSlots: {customRender: 'operation'}
          }]
      }
    },

    methods: {

      view (record) {
        this.userInfo.data = record
        if (this.userInfo.data.avatar.indexOf('https://') !== 0) {
          this.userInfo.data.newAvatar = `static/avatar/${this.userInfo.data.avatar}`
        } else {
          this.userInfo.data.newAvatar = this.userInfo.data.avatar
        }
        this.userInfo.visiable = true
      },
      add () {
        this.$refs.userEdit.show()
      },
      edit (record) {
        this.$refs.userEdit.show(record)
      },
      remove(record){
        let that = this
        this.$confirm({
          title: '确定删除用户?',
          content: '该操作不可撤销',
          centered: true,
          onOk () {
            that.$delete('user/' + record.userId).then(() => {
              that.$message.success('删除成功')
              that.selectedRowKeys = []
              that.search()
            })
          }
        })
      },

      handleUserInfoClose () {
        this.userInfo.visiable = false
      },

      batchDelete () {
        if (!this.selectedRowKeys.length) {
          this.$message.warning('请选择需要删除的记录')
          return
        }
        let that = this
        this.$confirm({
          title: '确定删除所选中的记录?',
          content: '当您点击确定按钮后，这些记录将会被彻底删除',
          centered: true,
          onOk () {
            let userIds = []
            for (let key of that.selectedRowKeys) {
              userIds.push(that.dataSource[key].userId)
            }
            that.$delete('user/' + userIds.join(',')).then(() => {
              that.$message.success('删除成功')
              that.selectedRowKeys = []
              that.search()
            })
          },
          onCancel () {
            that.selectedRowKeys = []
          }
        })
      },
      resetPassword () {
        if (!this.selectedRowKeys.length) {
          this.$message.warning('请选择需要重置密码的用户')
          return
        }
        let that = this
        this.$confirm({
          title: '确定重置选中用户密码?',
          content: '当您点击确定按钮后，这些用户的密码将会重置为1234qwer',
          centered: true,
          onOk () {
            let usernames = []
            for (let key of that.selectedRowKeys) {
              usernames.push(that.dataSource[key].username)
            }
            that.$put('user/password/reset', {
              usernames: usernames.join(',')
            }).then(() => {
              that.$message.success('重置用户密码成功')
              that.selectedRowKeys = []
            })
          },
          onCancel () {
            that.selectedRowKeys = []
          }
        })
      },
      exportExcel () {
        this.$refs.table.export("员工信息")
      },
      search () {
        this.$refs.table.getData()
      },

    }
  }
</script>
<style lang="less" scoped>
</style>
